import Swiper from 'swiper'

{
  new Swiper('.js-slide', {
    slidesPerView: 5,
    autoplay: {
      delay: 6000
    },
    speed: 3000,
    loop: true,
    noSwiping: true,
    noSwipingClass: '.js-slide',
    breakpoints: {
      768: {
        slidesPerView: 2.5,
        centeredSlides: true
      }
    }
  })

  const WHATIS_SLIDE = new Swiper('.js-whatis-slide', {
    slidesPerView: 1,
    spaceBetween: 100,
    speed: 1000,
    autoHeight: true,
    noSwiping: window.matchMedia('(min-width: 769px)').matches ? false : true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  })
  const PREV_ARROW = document.querySelector('.js-whatis-slide-prev-arrow')
  const NEXT_ARROW = document.querySelector('.js-whatis-slide-next-arrow')

  WHATIS_SLIDE.on('slideChange', () => {
    const slide = WHATIS_SLIDE.slides[WHATIS_SLIDE.activeIndex]
    // const slide: HTMLElement = WHATIS_SLIDE.slides[WHATIS_SLIDE.activeIndex]
    if (slide.classList.contains('js-whatis-slide-white-arrow')) {
      PREV_ARROW.classList.add('is-white-arrow')
      NEXT_ARROW.classList.add('is-white-arrow')
    } else {
      PREV_ARROW.classList.remove('is-white-arrow')
      NEXT_ARROW.classList.remove('is-white-arrow')
    }
  })
}
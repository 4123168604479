import SVG from 'svg.js'
import 'svg.easing.js'

{  
  if (document.getElementById('draw')) {
    const draw = SVG('draw').size(440, 650)
    const centerX = (draw.width() - 240) / 2
    const lid = draw.polygon('0,280 220,61.5 440,280 440,218.5 220,0 0,218.5')
    const sun = draw.path('M0,120C0,53.7,53.7,0,120,0c66.3,0,120,53.7,120,120')
    const sunMask = draw.mask()
    const sunRect = draw.rect(240, 120)
    const cup = draw.path('M373.2,330H220.4v-43.3h82.4L243.9,169l34.3-0.8c31.7-0.8,61.4-13.6,83.6-36.1c22.2-22.5,34.5-52.2,34.5-83.7v-5.1H0V0h440v48.4c0,42.8-16.7,83.3-46.9,114c-22.4,22.7-50.5,38.2-81.1,45.1L373.2,330z')
    
    const loopHighLight = (_s1: any, _s2: any, _delay: number) => {
      const _duration = 500
      _s1.animate({ ease: '<', duration: _duration, delay: _delay + 500 })
        .update({ offset: 1 })
        .after(() => {
          _s1.update({ offset: 0 })
          _s2.update({ offset: 0 })
          loopHighLight(_s1, _s2, 6000)
        })
      _s2.animate({ ease: '<', duration: _duration, delay: _delay })
        .update({ offset: 1, opacity: 0.8 })
    }
    
    const getDevice = () => {
      const ua = navigator.userAgent
      if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
        return 'sp'
      } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
        return 'tab'
      }
    }
    
    const changeDrawScale = (_draw: SVG.Doc, _split: number) => {
      const _scale = ((window.innerWidth / _split) / _draw.bbox().width)
      _draw.parent().style.cssText = `
        transform-origin: left top;
        transform: scale(${_scale})
      `
      // draw.style({ 'transform-origin': `-${draw.bbox().width / 2}px -${draw.bbox().height / 2}px` })
      //   .transform({ scale })
    }
    
    const device = getDevice()
    if (device === 'sp') changeDrawScale(draw, 3)
    if (device === 'tab' && window.innerWidth < window.innerHeight) changeDrawScale(draw, 3)
    window.addEventListener('resize', () => {
      if (device === 'sp') changeDrawScale(draw, 3)
      if (device === 'tab' && window.innerWidth < window.innerHeight) changeDrawScale(draw, 3)
    })
    
    lid.move(0, 0)
  
    const pattern = draw.pattern(100, 100, (add: any) => add.image('/assets/image/common/bg-red.jpg'))
    sunRect.move(centerX, draw.height() - cup.height() - sunRect.height()).fill(pattern)
  
    sun.fill('#fff')
      .move(centerX, (draw.height() - cup.height() - sunRect.height()) + sun.height())
      .animate({ ease: 'quadInOut', duration: 500, delay: 1000 })
      .translate(0, -sunRect.height())
      .after(() => {
        setTimeout(() => {
          document.querySelector('.js-logo').classList.add('is-animated')
          document.querySelector('.js-catchcopy').classList.add('is-animated')
        }, 2000)
      })
    sunRect.maskWith(sunMask.add(sun))
      
    cup.move(0, sunRect.bbox().y + sunRect.height()).fill('#000')
  }
}
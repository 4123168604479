import './../scss/style.scss'

import 'remodal'
import './module/logo'
import './module/slide'
import './module/modal'
import './module/gallery'

// Soomth scroll
import SweetScroll from 'sweet-scroll'
new SweetScroll({
  duration: 700,
  easing: 'easeInOutQuad'
})
{
  interface ModalContents {
    name: string,
    face: string,
    caption: string
  }

  const MODAL_SHOW = [].slice.call(document.getElementsByClassName('js-modal-show'))
  const NAME: HTMLElement = document.querySelector('.js-modal-name')
  const FACE: HTMLElement = document.querySelector('.js-modal-face')
  const CAPTION: HTMLElement = document.querySelector('.js-modal-caption')

  MODAL_SHOW.forEach((show: HTMLElement) => {
    show.addEventListener('click', () => {
      const data = JSON.parse(show.dataset.modalContents) as ModalContents
      NAME.innerText = data.name
      FACE.setAttribute('src', data.face)
      CAPTION.innerHTML = data.caption
    })
  })

  if (location.href.match(/#/)) history.replaceState(undefined, undefined, '/')
}